import React from "react";
import { m } from "framer-motion";
import useModulesData from "@staticQueries/ModulesQuery";
import { ModuleChip } from "@molecules";
import { Text, Container, Button, ButtonAlt, Image } from "@atoms";

const ModulesOverview = ({
  heading,
  descriptor,
  modules: _modules,
  button,
  solutionBuilderHeading,
  solutionBuilderLink,
  image,
}) => {
  const allModules = useModulesData();
  const uids = _modules.map(mod => mod.uid);
  const modules = allModules.filter(mod => uids.includes(mod.uid));
  return (
    <Container className="flex flex-wrap items-start gap-10">
      <div className="flex w-full flex-col gap-10 md:w-1/2">
        <Text variant="h2">{heading}</Text>
        <Text variant="xl">{descriptor}</Text>
      </div>
      <div className="ml-auto flex w-full flex-col gap-10 rounded-lg bg-black p-8 text-white shadow-lg md:w-1/3">
        <Text variant="h3" className="text-center">
          {solutionBuilderHeading}
        </Text>
        <div className="mx-auto w-2/5">
          <Image image={image} />
        </div>
        {solutionBuilderLink.url && (
          <div className="flex w-full items-center justify-center text-sm">
            <ButtonAlt to={solutionBuilderLink.url} color="black">
              {solutionBuilderLink.text}
            </ButtonAlt>
          </div>
        )}
      </div>
      <m.div
        initial="hidden"
        whileInView="show"
        transition={{ staggerChildren: 0.03 }}
        viewport={{ margin: "0px 0px -15% 0px" }}
        className="grid w-full grid-cols-1 items-start gap-5 sm:grid-cols-2 md:grid-cols-3"
      >
        {modules.map((mod, i) => (
          <ModuleChip {...mod} />
        ))}
      </m.div>
      {button.url && (
        <div className="flex w-full items-center justify-center text-sm">
          <Button to={button.url}>{button.text}</Button>
        </div>
      )}
    </Container>
  );
};

export default ModulesOverview;
