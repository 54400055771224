import React from "react";
import loadable from "@loadable/component";
import classNames from "classnames";
import CaseStudyGrid from "../organisms/CaseStudyGrid";
import ModulesOverview from "../organisms/ModulesOverview";

const ArticleGrid = loadable(() => import("@organisms/ArticleGrid"));
const BenefitList = loadable(() => import("@organisms/BenefitList"));
const ButtonList = loadable(() => import("@organisms/ButtonList"));
const CardCopy = loadable(() => import("@organisms/CardCopy"));
const CardGrid = loadable(() => import("@organisms/CardGrid"));
const CareerList = loadable(() => import("@organisms/CareerList"));
const CircleGrid = loadable(() => import("@organisms/CircleGrid"));
const Copy = loadable(() => import("@organisms/Copy"));
const Cta = loadable(() => import("@organisms/Cta"));
const CtaGrid = loadable(() => import("@organisms/CtaGrid"));
const DetailsGrid = loadable(() => import("@organisms/DetailsGrid"));
const FeatureColumns = loadable(() => import("@organisms/FeatureColumns"));
const FeatureHighlight = loadable(() => import("@organisms/FeatureHighlight"));
const FormBlock = loadable(() => import("@organisms/FormBlock"));
const HighlightedText = loadable(() => import("@organisms/HighlightedText"));
const IconGrid = loadable(() => import("@organisms/IconGrid"));
const ImageColumns = loadable(() => import("@organisms/ImageColumns"));
const ImageCopy = loadable(() => import("@organisms/ImageCopy"));
const ImpactGrid = loadable(() => import("@organisms/ImpactGrid"));
const OrganizationGrid = loadable(() => import("@organisms/OrganizationGrid"));
const SectionHeading = loadable(() => import("@organisms/SectionHeading"));
const SolutionsCarousel = loadable(() =>
  import("@organisms/SolutionsCarousel")
);
const SolutionsOverview = loadable(() =>
  import("@organisms/SolutionsOverview")
);
const SolutionWheel = loadable(() => import("@organisms/SolutionWheel"));
const Stats = loadable(() => import("@organisms/Stats"));
const TestimonialCarousel = loadable(() =>
  import("@organisms/TestimonialCarousel")
);
const TwoColumnCopy = loadable(() => import("@organisms/TwoColumnCopy"));
const VideoBlock = loadable(() => import("@organisms/VideoBlock"));
const SolutionBuilderLinks = loadable(() =>
  import("@organisms/SolutionSelect")
);

const PageBuilder = ({ blocks, hasHero, noHeight }) => {
  let overlapBackground = null;
  let prevBackground = null;
  let background = "bg-white";
  let prevBlock = null;
  let block = null;
  let flip = true;
  // const marginLessBlocks = [];

  const backgrounds = {
    white: "bg-white",
    offWhite: "bg-offwhite",
    lighterTeal: "bg-teal-lighter",
    lightTeal: "bg-teal-light",
    darkTeal: "bg-teal-dark",
    black: "bg-black",
  };

  return (
    <>
      {blocks.map((b, i) => {
        const { uid, type } = b;
        prevBlock = block;
        block = type;
        const spacing = classNames({
          "pb-10 sm:pb-20": prevBackground === background,
          "pt-4":
            prevBackground === background &&
            prevBlock === "sectionHeading" &&
            block !== "imageCopy",
          "py-10 sm:py-20":
            prevBackground !== background && prevBlock !== "sectionHeading",
          "pt-14 pb-10 sm:pt-24 sm:pt-20":
            prevBackground !== background && prevBlock === "sectionHeading",
        });
        const headerSpacing =
          prevBackground === background ? "pb-6" : "pt-10 pb-6 sm:pt-20";
        overlapBackground = prevBackground;
        prevBackground = background;
        background = backgrounds[b.bgColor] || background;
        switch (type) {
          case "articleGrid":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <ArticleGrid {...b} />
              </section>
            );
          case "benefitList":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <BenefitList {...b} color={background} />
              </section>
            );
          case "buttonList":
            return (
              <section
                key={uid}
                // * this component has its own spacing
                className={classNames(
                  "relative z-20 pb-10 sm:-mt-10 sm:pb-20",
                  background
                )}
              >
                <ButtonList {...b} />
              </section>
            );
          case "callToAction":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <Cta {...b} />
              </section>
            );
          case "callToActionGrid":
            return (
              <section
                key={uid}
                className={classNames("relative z-20 pb-10 sm:pb-20")}
              >
                <CtaGrid {...b} />
              </section>
            );
          case "cardCopy":
            return (
              <section
                key={uid}
                className={classNames("relative z-10", spacing, background)}
              >
                <CardCopy
                  {...b}
                  overlap={
                    i === 0 ||
                    (i === 1 && prevBlock === "utilityBackgroundColor")
                  }
                />
              </section>
            );
          case "cardGrid":
            return (
              <section
                key={uid}
                className={classNames("relative z-20", spacing, background)}
              >
                <CardGrid
                  overlap={
                    i === 0 ||
                    (i === 1 && prevBlock === "utilityBackgroundColor")
                  }
                  {...b}
                />
              </section>
            );
          case "careerList":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <CareerList key={uid} bgColor={overlapBackground} {...b} />
              </section>
            );
          case "caseStudyGrid":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <CaseStudyGrid {...b} />
              </section>
            );
          case "circleGrid":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <CircleGrid key={uid} {...b} />
              </section>
            );
          case "copy":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <Copy {...b} />
              </section>
            );
          case "detailsGrid":
            return (
              <section
                key={uid}
                className={classNames(
                  "relative",
                  {
                    "sm:pt-6":
                      prevBackground === background &&
                      prevBlock === "sectionHeading",
                  },
                  spacing,
                  background
                )}
              >
                <DetailsGrid background={background} {...b} />
              </section>
            );
          case "featureColumns":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <FeatureColumns {...b} />
              </section>
            );
          case "featureHighlight":
            return (
              <section
                key={uid}
                className={classNames(
                  "relative z-30 pb-14 sm:pb-24",
                  background
                )}
              >
                <FeatureHighlight {...b} />
              </section>
            );
          case "form":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <FormBlock {...b} />
              </section>
            );
          case "highlightedText":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <HighlightedText {...b} />
              </section>
            );
          case "iconGrid":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <IconGrid {...b} />
              </section>
            );
          case "imageColumns":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <ImageColumns color={background} {...b} />
              </section>
            );
          case "imageCopy":
            flip = prevBlock === "sectionHeading" ? flip : !flip;
            return (
              <section
                key={uid}
                className={classNames(
                  "relative",
                  {
                    "pt-14 sm:pt-24": prevBlock === "sectionHeading",
                    [spacing]: i !== 0,
                    "py-10 sm:pb-20 sm:pt-20 lg:pt-40": i === 0 && noHeight,
                  },
                  background
                )}
              >
                <ImageCopy {...b} color={background} flip={flip} />
              </section>
            );
          case "impactGrid":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <ImpactGrid key={uid} {...b} />
              </section>
            );
          case "modulesOverview":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <ModulesOverview key={uid} {...b} />
              </section>
            );
          case "organizationGrid":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <OrganizationGrid key={uid} {...b} />
              </section>
            );
          case "solutionBuilderLinks":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <SolutionBuilderLinks {...b} />
              </section>
            );
          case "solutionsCarousel":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <SolutionsCarousel color={background} key={uid} {...b} />
              </section>
            );
          case "solutionsOverview":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <SolutionsOverview key={uid} {...b} />
              </section>
            );
          case "solutionWheel":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <SolutionWheel key={uid} {...b} />
              </section>
            );
          case "sectionHeading":
            return (
              <section
                key={uid}
                // * this component has its own spacing
                className={classNames(
                  "relative z-10",
                  headerSpacing,
                  background
                )}
              >
                <SectionHeading
                  color={background}
                  variant={!hasHero && i === 0 ? "h1" : "h2"}
                  {...b}
                />
              </section>
            );
          case "stats":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <Stats {...b} />
              </section>
            );
          case "testimonialCarousel":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <TestimonialCarousel {...b} />
              </section>
            );
          case "twoColumnCopy":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <TwoColumnCopy {...b} />
              </section>
            );
          case "utilityAnchorLink":
            return <div className="anchorUtility" key={uid} id={b.anchor} />;
          case "video":
            return (
              <section
                key={uid}
                className={classNames("relative", spacing, background)}
              >
                <VideoBlock {...b} />
              </section>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

PageBuilder.defaultProps = {
  hasHero: true,
};

export default PageBuilder;
